import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useRef, useState, TouchEvent, useEffect } from "react";
import { Separator } from "../ui/separator";
import Icon from "../icon";
import confetti from "canvas-confetti";
import { useSnapshot } from "valtio";
import state, { Services } from "@/store/state";
import actions from "@/store/actions";

interface OfferCardProps {
  price: number;
  originalPrice: number;
  followers: number;
  onClaim: () => void;
  isClaimed: boolean;
}

function OfferCard({
  price,
  originalPrice,
  followers,
  onClaim,
  isClaimed,
}: OfferCardProps) {
  const cardRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const triggerConfetti = () => {
    if (!cardRef.current || !canvasRef.current) return;

    const canvas = canvasRef.current;
    const rect = cardRef.current.getBoundingClientRect();
    
    canvas.width = rect.width;
    canvas.height = rect.height;

    const myConfetti = confetti.create(canvas, {
      resize: true,
      useWorker: true
    });

    const end = Date.now() + 3 * 1000;
    const colors = ["#a786ff", "#fd8bbc", "#eca184", "#f8deb1"];

    const frame = () => {
      if (Date.now() > end) {
        myConfetti.reset();
        return;
      }

      const timeLeft = end - Date.now();
      const particleCount = timeLeft > 1000 ? 4 : 1;

      myConfetti({
        particleCount,
        angle: 340,
        spread: 150,
        origin: { x: 0.2, y: 1 },
        startVelocity: 15,
        gravity: 1.2,
        scalar: 0.9,
        drift: -0.5,
        ticks: 600,
        decay: 0.94,
        shapes: ['square'],
      });

      myConfetti({
        particleCount,
        angle: 200,
        spread: 150,
        origin: { x: 0.8, y: 1 },
        startVelocity: 15,
        gravity: 1.2,
        scalar: 0.9,
        drift: 0.5,
        ticks: 600,
        decay: 0.94,
        shapes: ['square'],
      });

      requestAnimationFrame(frame);
    };

    frame();
  };

  const handleClaim = () => {
    onClaim();
    triggerConfetti();
  };

  return (
    <div 
      ref={cardRef}
      className="relative flex min-w-[208px] p-3 flex-col items-center rounded-[12px] border border-gray-900 bg-white shadow-[0px_0px_0px_2px_#FFF,0px_0px_0px_3px_#F4F4F5]"
    >
      <canvas
        ref={canvasRef}
        className="absolute inset-0 pointer-events-none z-20"
        style={{
          borderRadius: '12px',
        }}
      />
      {isClaimed && (
        <div
          className="absolute inset-0 flex items-center justify-center z-10 rounded-[12px]"
          style={{
            border: "0.5px solid rgba(255, 255, 255, 0.16)",
            background: "rgba(255, 255, 255, 0.12)",
            backdropFilter: "blur(3px)",
          }}
        >
          <div className="w-8 h-8 rounded-[6px] border border-gray-900 bg-white flex items-center justify-center backdrop-blur-[1px]">
            <Icon icon="check-line" className="w-6 h-6 text-gray-900" />
          </div>
        </div>
      )}
      <div className="!text-body-2xs-m text-gray-900 text-center">
        One Time Offer!
      </div>
      <p className="text-gray-400 text-center text-detail">
        Now if you add {followers.toLocaleString()} more followers, it's only{" "}
        <span className="text-gray-900 text-detail font-medium">${price}</span>{" "}
        instead of ${originalPrice}.
      </p>
      <Button
        onClick={handleClaim}
        disabled={isClaimed}
        className="w-full mt-[10px] !bg-black text-white rounded-[6px] !py-2 px-2 max-h-6 !text-body-2xs-m disabled:opacity-50"
      >
        {isClaimed ? "Claimed" : "Claim Offer"}
      </Button>
    </div>
  );
}

export function SpecialOffers() {
  const containerRef = useRef<HTMLDivElement>(null);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { selectedSpecialOffers } = useSnapshot(state);

  const offers = [
    { price: 49.99, originalPrice: 79.99, followers: 5000 },
    { price: 89.99, originalPrice: 129.99, followers: 10000 },
    { price: 149.99, originalPrice: 199.99, followers: 20000 },
    { price: 199.99, originalPrice: 299.99, followers: 30000 },
    { price: 299.99, originalPrice: 399.99, followers: 50000 },
    { price: 499.99, originalPrice: 699.99, followers: 100000 },
  ];

  const handleScroll = (direction: 'prev' | 'next') => {
    if (!containerRef.current) return;
    
    const cardWidth = 280;
    const container = containerRef.current;
    const visibleWidth = container.clientWidth;
    const scrollWidth = container.scrollWidth;
    const currentScroll = container.scrollLeft;
    
    if (direction === 'next') {
      if (currentScroll + visibleWidth >= scrollWidth - 20) {
        container.scrollTo({ left: 0, behavior: 'smooth' });
        setCurrentIndex(0);
      } else {
        container.scrollTo({ 
          left: currentScroll + cardWidth,
          behavior: 'smooth' 
        });
        setCurrentIndex(prev => prev + 1);
      }
    } else {
      if (currentScroll <= 20) {
        container.scrollTo({ 
          left: scrollWidth - visibleWidth,
          behavior: 'smooth' 
        });
        setCurrentIndex(offers.length - 1);
      } else {
        container.scrollTo({ 
          left: currentScroll - cardWidth,
          behavior: 'smooth' 
        });
        setCurrentIndex(prev => prev - 1);
      }
    }
  };

  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    setIsDragging(true);
    if (!containerRef.current) return;

    setStartX(e.touches[0].pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    if (!isDragging || !containerRef.current) return;

    e.preventDefault();
    const x = e.touches[0].pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  return (
    <Card className="w-full rounded-[8px] border border-gray-100 shadow-none p-3 mb-4">
      <div className="flex flex-col gap-[2px]">
        <div className="flex items-end justify-between">
          <div>
            <h2 className="text-body-xs-m leading-5">Special Offers 🔥</h2>
            <p className="text-gray-400 text-body-2xs-r">
              Select your offers to add the likes.
            </p>
          </div>
          <div className="flex gap-2">
            <div
              className="w-7 h-7 border border-gray-100 rounded-[6px] bg-gray-50 flex items-center justify-center cursor-pointer"
              onClick={() => handleScroll('prev')}
            >
              <Icon icon="arrow-left-line" className="w-5 h-5 text-gray-900" />
            </div>
            <div
              className="w-7 h-7 border border-gray-100 rounded-[6px] bg-gray-50 flex items-center justify-center cursor-pointer"
              onClick={() => handleScroll('next')}
            >
              <Icon icon="arrow-right-line" className="w-5 h-5 text-gray-900" />
            </div>
          </div>
        </div>
      </div>

      <Separator className="my-3" orientation="horizontal" />

      <div
        ref={containerRef}
        className="overflow-x-auto overflow-y-hidden scrollbar-hide touch-pan-x w-full"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div className="flex items-start gap-3 w-full px-[0.20rem] py-[0.20rem] h-full">
          {offers.map((offer, index) => (
            <OfferCard
              key={index}
              {...offer}
              isClaimed={selectedSpecialOffers.some(
                (claimed) => claimed.amount === offer.followers
              )}
              onClaim={() => {
                if (!isDragging) {
                  actions.claimSpecialOffer({
                    service: Services.FOLLOWERS,
                    amount: offer.followers,
                    originalPrice: offer.originalPrice,
                    discountedPrice: offer.price,
                  });
                }
              }}
            />
          ))}
        </div>
      </div>
    </Card>
  );
}
