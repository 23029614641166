import { Services } from "@/store/state";
import { cn } from "@/lib/utils";
import { Separator } from "./ui/separator";
import Icon from "./icon";
import { motion } from "framer-motion";

interface SelectedSpecialOfferProps {
  service: Services;
  amount: number;
  originalPrice: number;
  discountedPrice: number;
  onRemove?: () => void;
  className?: string;
}

export function SelectedSpecialOffer({
  service,
  amount,
  originalPrice,
  discountedPrice,
  onRemove,
  className,
}: SelectedSpecialOfferProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3, ease: "easeOut" }}
      className={cn(
        "flex items-center justify-between p-2 min-w-[303px] h-[48px] bg-white rounded-xl border border-[#35B9E9] shadow-[0px_0px_0px_3px_#FFF,0px_0px_0px_4px_#F4F4F5]",
        className
      )}
    >
      <div className="flex items-center gap-3">
        <div className="w-8 h-8 rounded-[30.667px] bg-[#EBFAFF] flex items-center justify-center shadow-[0px_0px_0px_2px_#EBFAFF,inset_0px_2.8px_7.064px_2.8px_#35B9E9]">
          <span className="text-blue-500 text-[12px]">🔥</span>
        </div>
        <div>
          <h3 className="text-body-2xs-m text-gray-900">
            +{amount.toLocaleString()} {service}
          </h3>
          <div className="flex items-center">
            <span className="text-gray-400 line-through text-detail">
              ${originalPrice.toFixed(2)}
            </span>
            <Separator orientation="vertical" className="h-3 mx-[6px]" />
            <span className="text-detail text-gray-900">
              ${discountedPrice.toFixed(2)}
            </span>
          </div>
        </div>
      </div>
      {onRemove && (
        <button
          onClick={onRemove}
          className="p-[6px] border border-gray-100 bg-gray-50 hover:bg-gray-100 rounded-[6px] w-[28px] h-[28px] flex items-center justify-center transition-colors"
        >
          <Icon icon="close-fill" className="w-4 h-4 text-gray-400" />
        </button>
      )}
    </motion.div>
  );
}
