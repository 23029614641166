import React, { useEffect } from "react";
import { useSnapshot } from "valtio";
import state, {
  Services,
  Products,
  Steps,
  Followers,
  PostBasedProduct,
  ServiceSubStep,
} from "@/store/state";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import actions from "@/store/actions";
import { Separator } from "../ui/separator";
import { priceList } from "@/config";
import Icon from "../icon";
import { VerifiedBadge } from "../profile-card";
import { FollowersIcon, LikesIcon, ViewsIcon, SharesIcon } from "../stat-icons";
import { DrawerState } from "@/types/drawer-states";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { SpecialOffers } from "./special-offers";
import { SelectedSpecialOffer } from "../selected-special-offer";
import { AnimatePresence } from "framer-motion";

const serviceIcons = {
  [Services.FOLLOWERS]: <FollowersIcon />,
  [Services.LIKES]: <LikesIcon />,
  [Services.VIEWS]: <ViewsIcon />,
  [Services.SHARES]: <SharesIcon />,
};

export default function CheckoutBasketItems() {
  const { cart, selectedSpecialOffers } = useSnapshot(state);
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const [itemToRemove, setItemToRemove] = React.useState<{
    username?: string;
    service?: Services;
    serviceId?: string;
    specialOfferId?: string;
  } | null>(null);

  useEffect(() => {
    const isCartEmpty = cart.every((userCart) =>
      Object.values(userCart.products).every(
        (products) => products.length === 0
      )
    );

    if (isCartEmpty) {
      actions.updateCurrentStep(Steps.CHOOSE_SERVICE);
      actions.setServiceSubStep(ServiceSubStep.SERVICE_SELECTION);
      actions.hideBasketSummary();

      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete("subStep");
      const newUrl = `${window.location.pathname}${
        searchParams.toString() ? `?${searchParams.toString()}` : ""
      }`;
      window.history.replaceState({}, "", newUrl);
    }
  }, [cart]);

  const handleQuantityChange = (
    username: string,
    service: Services,
    serviceId: string,
    increase: boolean,
    currentCount: number
  ) => {
    const isFollowers = service === Services.FOLLOWERS;
    if (increase) {
      if (isFollowers) {
        actions.increaseQuantity(username, service, serviceId);
      } else {
        actions.openServiceSpecificDrawer(service, username, serviceId);
        actions.setDrawerState(DrawerState.SERVICE_SPECIFIC);
      }
    } else {
      if (currentCount === 1) {
        setItemToRemove({ username, service, serviceId });
        setIsAlertOpen(true);
      } else {
        if (isFollowers) {
          actions.decreaseQuantity(username, service, serviceId);
        } else {
          actions.openServiceSpecificDrawer(service, username, serviceId);
          actions.setDrawerState(DrawerState.SERVICE_SPECIFIC);
        }
      }
    }
  };

  const handleSpecialOfferRemove = (offerId: string) => {
    setItemToRemove({ specialOfferId: offerId });
    setIsAlertOpen(true);
  };

  const handleRemoveConfirm = () => {
    if (itemToRemove) {
      if (itemToRemove.specialOfferId) {
        actions.removeSpecialOffer(itemToRemove.specialOfferId);
      } else if (
        itemToRemove.username &&
        itemToRemove.service &&
        itemToRemove.serviceId
      ) {
        actions.removeFromCart(
          itemToRemove.username,
          itemToRemove.service,
          itemToRemove.serviceId
        );
      }
    }
    setIsAlertOpen(false);
    setItemToRemove(null);
  };

  const handleItemClick = (
    username: string,
    service: Services,
    serviceId: string
  ) => {
    actions.openServiceSpecificDrawer(service, username, serviceId);
    actions.setDrawerState(DrawerState.SERVICE_SELECTION);
  };

  const renderProductItems = (products: Products, username: string) => {
    return (
      <>
        {Object.entries(products).map(([service, items]) =>
          items.map((item) => {
            const servicePack = priceList[service as Services].find(
              (pack) => pack.id === Number(item.service_id)
            );
            if (!servicePack) return null;

            const isFollowerService = service === Services.FOLLOWERS;
            const followerItem = isFollowerService ? (item as Followers) : null;
            const postBasedItem = !isFollowerService
              ? (item as PostBasedProduct)
              : null;

            const quantity = isFollowerService
              ? followerItem!.count
              : postBasedItem!.posts.length;
            const totalPrice = servicePack.price * quantity;

            const selectedPosts = !isFollowerService
              ? postBasedItem!.posts
              : [];

            const selectedPostImages = selectedPosts
              .map(
                (postId) =>
                  state.userFeed?.items.find(
                    (feedItem) => feedItem.id === postId
                  )?.videoCover
              )
              .filter(Boolean) as string[];

            return (
              <div
                key={`${service}-${item.service_id}`}
                className="flex items-center justify-between cursor-pointer"
                onClick={() =>
                  handleItemClick(
                    username,
                    service as Services,
                    item.service_id
                  )
                }
              >
                <div className="flex items-center space-x-2">
                  {serviceIcons[service as Services]}
                  <div>
                    <p className="text-gray-900 text-body-2xs-m">
                      +{servicePack.amount.toLocaleString()} {service}
                    </p>
                    <div className="flex items-center space-x-2">
                      <span className="text-detail text-gray-400 line-through">
                        ${(servicePack.originalPrice * quantity).toFixed(2)}
                      </span>
                      <Separator
                        orientation="vertical"
                        className="h-3 mx-[6px]"
                      />
                      <span className="text-gray-900 text-detail">
                        ${totalPrice.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
                {!isFollowerService && (
                  <div className="flex items-center -space-x-3">
                    {selectedPostImages.slice(0, 3).map((image, index) => (
                      <img
                        key={index}
                        className="h-6 w-6 rounded-full border-[1.2px] border-white"
                        src={image}
                        alt={`Selected post ${index + 1}`}
                      />
                    ))}
                    {selectedPosts.length > 3 && (
                      <div className="h-6 w-6 rounded-full bg-gray-50 z-20 flex items-center justify-center text-gray-900 text-detail border-[1.2px] border-white">
                        +{selectedPosts.length - 3}
                      </div>
                    )}
                  </div>
                )}
                <div
                  className="flex items-center"
                  onClick={(e) => e.stopPropagation()}
                >
                  <Button
                    variant="outline"
                    size="icon"
                    className="h-[28px] w-[28px] rounded-r-none border-r-0 rounded-l-md bg-gray-50 text-gray-400"
                    onClick={() =>
                      handleQuantityChange(
                        username,
                        service as Services,
                        item.service_id,
                        false,
                        quantity
                      )
                    }
                  >
                    {quantity === 1 ? (
                      <Icon icon="delete-bin-5-line" className="h-4 w-4" />
                    ) : (
                      <Icon icon="minus-fill" className="h-4 w-4" />
                    )}
                    <span className="sr-only">
                      {quantity === 1 ? "Remove item" : "Decrease quantity"}
                    </span>
                  </Button>
                  <div className="h-[28px] w-[28px] flex items-center justify-center bg-gray-900 text-white text-detail font-semibold">
                    {quantity}
                  </div>
                  <Button
                    variant="outline"
                    size="icon"
                    className="h-[28px] w-[28px] rounded-l-none border-l-0 rounded-r-md bg-gray-50 text-gray-400"
                    onClick={() =>
                      handleQuantityChange(
                        username,
                        service as Services,
                        item.service_id,
                        true,
                        quantity
                      )
                    }
                  >
                    <Icon icon="add-line" className="h-4 w-4" />
                    <span className="sr-only">Increase quantity</span>
                  </Button>
                </div>
              </div>
            );
          })
        )}
        {selectedSpecialOffers?.length > 0 && (
          <div className="mt-4 space-y-3">
            <AnimatePresence>
              {selectedSpecialOffers.map((offer) => (
                <SelectedSpecialOffer
                  key={offer.id}
                  service={offer.service}
                  amount={offer.amount}
                  originalPrice={offer.originalPrice}
                  discountedPrice={offer.discountedPrice}
                  onRemove={() => handleSpecialOfferRemove(offer.id)}
                />
              ))}
            </AnimatePresence>
          </div>
        )}
      </>
    );
  };

  const getAlertMessage = (item: typeof itemToRemove) => {
    if (!item) return "";
    const baseMessage =
      "will be removed from your basket. Are you sure you want to remove it?";

    if (item.specialOfferId) {
      const offer = selectedSpecialOffers?.find(
        (o) => o.id === item.specialOfferId
      );
      if (offer) {
        return `${offer.amount.toLocaleString()} ${
          offer.service
        } ${baseMessage}`;
      }
      return "";
    }

    const amount = priceList[item.service!]
      .find((pack) => pack.id === Number(item.serviceId))
      ?.amount.toLocaleString();
    return `${amount} ${item.service} ${baseMessage}`;
  };

  return (
    <>
      {cart.map((userCart) => (
        <Card
          key={userCart.username}
          className="w-full rounded-[8px] border border-gray-100 shadow-none max-w-full p-3 space-y-3 mb-4"
        >
          <div className="flex items-center space-x-2 max-h-8">
            <div className="relative">
              <Avatar className="h-8 w-8">
                <AvatarImage
                  src={state.user?.profilePicture}
                  alt={userCart.username}
                />
                <AvatarFallback>
                  {userCart.username.slice(0, 2).toUpperCase()}
                </AvatarFallback>
              </Avatar>
              <VerifiedBadge className="-top-[3px] -right-[2px] !w-4 !h-4" />
            </div>
            <div>
              <h3 className="text-gray-900 text-body-2xs-m">
                @{userCart.username}
              </h3>
              <p className="text-gray-400 text-detail">{state.user?.name}</p>
            </div>
          </div>
          <Separator className="!mt-4" />
          {renderProductItems(userCart.products, userCart.username)}
        </Card>
      ))}
      <SpecialOffers />
      <AlertDialog open={isAlertOpen} onOpenChange={setIsAlertOpen}>
        <AlertDialogContent className="max-w-[327px] rounded-[12px] px-4 py-5 border-t border-b border-gray-100 bg-gray-50 shadow-[0px_17px_10px_0px_rgba(0,_0,_0,_0.02)_inset,_0px_0px_0px_2px_#FFF,_0px_0px_0px_3px_#F4F4F5,_0px_46px_13px_0px_rgba(0,_0,_0,_0.00),_0px_29px_12px_0px_rgba(0,_0,_0,_0.01),_0px_17px_10px_0px_rgba(0,_0,_0,_0.03),_0px_7px_7px_0px_rgba(0,_0,_0,_0.05),_0px_2px_4px_0px_rgba(0,_0,_0,_0.06)]">
          <AlertDialogHeader className="space-y-1">
            <AlertDialogTitle className="!text-body-sm-m text-gray-900 text-center">
              Remove Item? 🤔
            </AlertDialogTitle>
            <AlertDialogDescription className="!text-body-2xs-r text-gray-400 text-center">
              {getAlertMessage(itemToRemove)}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="flex flex-row justify-center items-center gap-4">
            <AlertDialogAction
              onClick={handleRemoveConfirm}
              className="bg-red-100 max-h-8 focus:bg-red-200 hover:border-red-300 hover:shadow-none focus:shadow-none !focus:ring-0 hover:ring-0 border flex-1 !border-white text-red-300 !text-body-xs-m shadow-[0px_1px_2px_0px_var(--Red-200,#F8C9D2),0px_0px_0px_1px_var(--Red-200,#F8C9D2)]"
            >
              Remove
            </AlertDialogAction>
            <AlertDialogCancel className="mt-0 flex-1 max-h-8 bg-white border-gray-100 text-body-xs-m">
              Cancel
            </AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
